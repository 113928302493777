import React, { useState, useEffect } from 'react';
import './LandingDashboard.css';
import HrDashboardModal from './HrDashboardModal';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import DateRangePicker from 'src/views/common/dateRangePicker';
import moment from 'moment';
import HrDashboardJoinedModal from './HrDashboardJoinedModal';
import { isPermission } from 'src/utils/common';
const MrfDashboard = () => {
  const api = new API();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenJoined, setIsOpenJoined] = useState(false);

  const [modalHeading, setModalHeading] = useState('');
  const [dashboardData, setDashboardData] = useState({});
  const [topRecruiters, setTopRecruiters] = useState([]);
  const [avgTat, setAvgTat] = useState();
  // const [startDate, setStartDate] = useState( moment( moment().subtract(30, 'days') ).format("YYYY-MM-DD"))
  // const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
  );
  const [onclickMrfData, setOnclickMrfData] = useState([]);

  const getAllDashboardCount = async () => {
    let data = {
      from: startDate,
      to: endDate,
    };
    let result = await api.get(
      config.get_mrf_details_for_hr_dash_count,
      true,
      data,
    );
    if (result && result.code === 200) {
      setDashboardData(result && result.data);
    } 
    // else {
    //   alert(result && result.message);
    // }
  };
  const getTopRecruiters = async () => {
    let data = {
      from: startDate,
      to: endDate,
    };

    let result = await api.get(
      config.find_top_recruiter_for_mrf_dashboard,
      true,
      data,
    );
    if (result && result.code === 200) {
      setTopRecruiters(result && result.data);
    } else {
      // console.log(result && result.message)
    }
  };
  const getAvgTat = async () => {
    let data = {
      from: startDate,
      to: endDate,
    };

    let result = await api.get(config.total_counts_for_mrf_tat, true, data);
    if (result && result.code === 200) {
      setAvgTat(result && result.data);
    } else {
      // console.log(result && result.message)
    }
  };
  useEffect(() => {
    getAllDashboardCount();
    getTopRecruiters();
    getAvgTat();
  }, []);

  const isViewModalOpen = type => {
    if (type == 'joined') {
      // alert("inside joined")
      setIsOpenJoined(!isOpenJoined);
    } else {
      // alert("inside not joined")
      setIsOpen(!isOpen);
    }
  };
  const downLoadMRFData = async () => {
    let data = {
      from: startDate,
      to: endDate,
    };
    let result = await api.get(config.dataforMRF, true, data);
    if (result && result.code == 200) {
      window.open(result?.data?.file_path, '_blank');
    }
  };
  const getDataForHrDashBoard = async (
    typeOfData,
    heading,
    mrf_status,
    mrf_type,
    type = '',
  ) => {
    if(!isPermission('mrf_tab_data')){
        return;
    }
    setOnclickMrfData('');
    isViewModalOpen(type);
    let data = {
      from: startDate,
      to: endDate,
      mrf_status: mrf_status,
      mrf_type: mrf_type,
    };
    let result = await api.get(
      config.get_mrf_detatils_data_hr_dash,
      true,
      data,
    );
    if (result && result.code === 200) {
      setOnclickMrfData(result && result.data);
    }

    setModalHeading(heading);
  };
  useEffect(() => {}, []);
  const filterMrfDash = async () => {
    getAllDashboardCount();
    getTopRecruiters();
    getAvgTat();
  };
  return (
    <>
      <div className="container-fluid dashbord-boby">
        <div className="card">
          <div className=" row">
            <div className="col-md-12  dashboard-header">
              <div className="row align-items-center">
                <div className="col-md">
                  <strong>MRF DASHBOARD</strong>
                </div>
                <div className="col-md text-right">
                  <div className="row ">
                    {(isPermission('filter_mrf_dashboard') ||
                      isPermission('download_mrf_data_report')) && (
                      <div className="col-md-7 header-right-section">
                        <DateRangePicker
                          startDefaultValue={startDate}
                          endDefaultValue={endDate}
                          DateStart={setStartDate}
                          DateEnd={setEndDate}
                        />
                      </div>
                    )}
                    {isPermission('filter_mrf_dashboard') && (
                      <div
                        className="col-md-1 filter_download_button"
                        style={{ paddingRight: '10%' }}
                      >
                        <button
                          onClick={filterMrfDash}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-filter" aria-hidden="true"></i>{' '}
                          Filter
                        </button>
                      </div>
                    )}
                    {isPermission('download_mrf_data_report') && (
                      <div className="col-md-3 filter_download_button">
                        <button
                          onClick={downLoadMRFData}
                          className="btn btn-success"
                        >
                          <i className="fa fa-download" aria-hidden="true"></i>{' '}
                          Download
                          {/* <a href="https://cbt.glueple.com:3021/mrf_reports/MRF_Data.xlsx" target="_blank"></a> */}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <div
                  className="card"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px 0px grey',
                    minHeight: "160px"
                  }}
                >
                  <div className="col mrf-card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div 
                          onClick={() =>
                            getDataForHrDashBoard(
                              'openMrf',
                              'Total Raised MRF',
                              'raised',
                              'all',
                              'raised',
                            )
                          }
                          className="float-left  left-section"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <div className="justify-content-center">
                            <h6>MRF RAISED</h6>
                            <h6 style={{ fontWeight: 700, fontSize: 22 }}>
                              {dashboardData.total_open_mrf
                                ? dashboardData.total_open_mrf
                                : '0'}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="float-right right-section"
                          style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'openVacancy',
                                  'Raised MRF Vacancy',
                                  'raised',
                                  'vacancy',
                                )
                              }
                              className="vacancy-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Vacancy</span>
                              <br />
                              <span>
                                <h6>
                                  {dashboardData.total_open_vacancy
                                    ? dashboardData.total_open_vacancy
                                    : '0'}
                                </h6>
                              </span>
                            </div>
                            <br />
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'openReplacement',
                                  'Raised MRF Replacement',
                                  'raised',
                                  'replacement',
                                )
                              }
                              className="replacement-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Replacement</span>
                              <br />
                              <span>
                                <h5>
                                  {dashboardData.total_open_replacement
                                    ? dashboardData.total_open_replacement
                                    : '0'}
                                </h5>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="card"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px 0px grey',
                    minHeight: "160px"
                  }}
                >
                  <div className="col mrf-card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          onClick={() =>
                            getDataForHrDashBoard(
                              'opsSelect',
                              'Total Open MRF',
                              'open',
                              'all',
                            )
                          }
                          className="float-left  left-section"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <div className="justify-content-center">
                            <h6>OPEN MRF HR</h6>
                            <h6 style={{ fontWeight: 700, fontSize: 22 }}>
                              {dashboardData.total_o_mrf
                                ? dashboardData.total_o_mrf
                                : '0'}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div
                          className="float-right right-section"
                          style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'opsSelectVacancy',
                                  'Open MRF Vacancy',
                                  'open',
                                  'vacancy',
                                )
                              }
                              className="vacancy-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Vacancy</span>
                              <br />

                              <span>
                                <h6>
                                  {dashboardData.total_o_mrf_vacancy
                                    ? dashboardData.total_o_mrf_vacancy
                                    : '0'}
                                </h6>
                              </span>
                            </div>
                            <br />
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'opsSelectReplacement',
                                  'Open MRF Replacement',
                                  'open',
                                  'replacement',
                                )
                              }
                              className="replacement-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Replacement</span>
                              <br />
                              <span>
                                <h5>
                                  {dashboardData.total_o_mrf_replacement
                                    ? dashboardData.total_o_mrf_replacement
                                    : '0'}
                                </h5>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="card"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px 0px grey',
                    minHeight: "160px"
                  }}
                >
                  <div className="col mrf-card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          onClick={() =>
                            getDataForHrDashBoard(
                              'hrSelect',
                              'Total HR Select MRF',
                              'hr_select',
                              'all',
                            )
                          }
                          className="float-left  left-section"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <div className="justify-content-center">
                            <h6>HR SELECT</h6>
                            <h6 style={{ fontWeight: 700, fontSize: 22 }}>
                              {dashboardData.total_unique_mrf_count_for_vacancy_replacement_for_hr_candidate_created
                                ? dashboardData.total_unique_mrf_count_for_vacancy_replacement_for_hr_candidate_created
                                : '0'}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div
                          className="float-right right-section"
                          style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'hrSelectVacancy',
                                  'HR Select Vacancy',
                                  'hr_select',
                                  'vacancy',
                                )
                              }
                              className="vacancy-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Vacancy</span>
                              <br />
                              <span>
                                <h6>
                                  {dashboardData.total_unique_mrf_vacancy_for_candidate_created
                                    ? dashboardData.total_unique_mrf_vacancy_for_candidate_created
                                    : '0'}
                                  /
                                  {dashboardData.total_candidate_created_for_vacancy
                                    ? dashboardData.total_candidate_created_for_vacancy
                                    : '0'}
                                </h6>
                              </span>
                            </div>
                            <br />
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'hrSelectReplacement',
                                  'HR Select Replacement',
                                  'hr_select',
                                  'replacement',
                                )
                              }
                              className="replacement-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Replacement</span>
                              <br />
                              <span>
                                <h5>
                                  {dashboardData.total_unique_mrf_replacement_for_candidate_created
                                    ? dashboardData.total_unique_mrf_replacement_for_candidate_created
                                    : '0'}
                                  /
                                  {dashboardData.total_candididate_created_for_replacement
                                    ? dashboardData.total_candididate_created_for_replacement
                                    : '0'}
                                </h5>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div
                  className="card"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px 0px grey',
                    minHeight: "160px",
                  }}
                >
                  <div className="col mrf-card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          onClick={() =>
                            getDataForHrDashBoard(
                              'opsSelect',
                              'Total OPS Select MRF',
                              'ops_select',
                              'all',
                            )
                          }
                          className="float-left  left-section"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <div className="justify-content-center">
                            <h6>OPS SELECT</h6>
                            <h6 style={{ fontWeight: 700, fontSize: 22 }}>
                              {dashboardData.total_unique_mrf_vacancy_replacement_for_candidate_selected
                                ? dashboardData.total_unique_mrf_vacancy_replacement_for_candidate_selected
                                : '0'}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="float-right right-section"
                          style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'opsSelectVacancy',
                                  'Ops select Vacancy',
                                  'ops_select',
                                  'vacancy',
                                )
                              }
                              className="vacancy-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Vacancy</span>
                              <br />

                              <span>
                                <h6>
                                  {dashboardData.total_unique_mrf_vacancy_for_candididate_selected
                                    ? dashboardData.total_unique_mrf_vacancy_for_candididate_selected
                                    : '0'}
                                  /
                                  {dashboardData.total_ops_selected_candidate_for_vacancy
                                    ? dashboardData.total_ops_selected_candidate_for_vacancy
                                    : '0'}
                                </h6>
                              </span>
                            </div>
                            <br />
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'opsSelectReplacement',
                                  'Ops Select Replacement',
                                  'ops_select',
                                  'replacement',
                                )
                              }
                              className="replacement-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Replacement</span>
                              <br />
                              <span>
                                <h5>
                                  {dashboardData.total_unique_mrf_replacement_for_candididate_selected
                                    ? dashboardData.total_unique_mrf_replacement_for_candididate_selected
                                    : '0'}
                                  /
                                  {dashboardData.total_ops_selected_candidate_for_replacement
                                    ? dashboardData.total_ops_selected_candidate_for_replacement
                                    : '0'}
                                </h5>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div
                  className="card"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px 0px grey',
                    minHeight: "160px"
                  }}
                >
                  <div className="col mrf-card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          onClick={() =>
                            getDataForHrDashBoard(
                              'offeredMrf',
                              'Total Offered MRF',
                              'offered',
                              'all',
                            )
                          }
                          className="float-left  left-section"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <div className="justify-content-center">
                            <h6>OFFERED</h6>
                            <h6 style={{ fontWeight: 700, fontSize: 22 }}>
                              {dashboardData.total_unique_mrf_vacancy_replcement_for_offer
                                ? dashboardData.total_unique_mrf_vacancy_replcement_for_offer
                                : '0'}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div
                          className="float-right right-section"
                          style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'offeredVacancy',
                                  'Offered vacancy',
                                  'offered',
                                  'vacancy',
                                )
                              }
                              className="vacancy-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Vacancy</span>
                              <br />

                              <span>
                                <h6>
                                  {dashboardData.total_unique_mrf_vacancy_for_offer_send_candidate
                                    ? dashboardData.total_unique_mrf_vacancy_for_offer_send_candidate
                                    : '0'}
                                  /
                                  {dashboardData.total_offered_candidate_for_vacancy
                                    ? dashboardData.total_offered_candidate_for_vacancy
                                    : '0'}
                                </h6>
                              </span>
                            </div>
                            <br />
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'offeredReplacement',
                                  'Offered Replacement',
                                  'offered',
                                  'replacement',
                                )
                              }
                              className="replacement-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Replacement</span>
                              <br />
                              <span>
                                <h5>
                                  {dashboardData.total_unique_mrf_replacement_for_offer_send_candidate
                                    ? dashboardData.total_unique_mrf_replacement_for_offer_send_candidate
                                    : '0'}
                                  /
                                  {dashboardData.total_offered_candidate_for_replacement
                                    ? dashboardData.total_offered_candidate_for_replacement
                                    : '0'}
                                </h5>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="card"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px 0px grey',
                    minHeight: "160px"
                  }}
                >
                  <div className="col mrf-card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          onClick={() =>
                            getDataForHrDashBoard(
                              'acceptedMrf',
                              'Total Accepted MRF',
                              'offer_accepted',
                              'all',
                            )
                          }
                          className="float-left  left-section"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <div className="justify-content-center">
                            <h6>ACCEPTED</h6>
                            <h6 style={{ fontWeight: 700, fontSize: 22 }}>
                              {dashboardData.total_unique_mrf_vacancy_replacement_for_offer_accepted
                                ? dashboardData.total_unique_mrf_vacancy_replacement_for_offer_accepted
                                : '0'}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div
                          className="float-right right-section"
                          style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'acceptedVacancy',
                                  'Accepted Vacancy',
                                  'offer_accepted',
                                  'vacancy',
                                )
                              }
                              className="vacancy-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Vacancy</span>
                              <br />

                              <span>
                                <h6>
                                  {dashboardData.unique_mrf_vacancy_for_candidate_offer_accepted
                                    ? dashboardData.unique_mrf_vacancy_for_candidate_offer_accepted
                                    : '0'}
                                  /
                                  {dashboardData.total_offer_letter_accepted_candidate_for_vacancy
                                    ? dashboardData.total_offer_letter_accepted_candidate_for_vacancy
                                    : '0'}
                                </h6>
                              </span>
                            </div>
                            <br />
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'acceptedReplacement',
                                  'Accepted Replacement',
                                  'offer_accepted',
                                  'replacement',
                                )
                              }
                              className="replacement-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Replacement</span>
                              <br />
                              <span>
                                <h5>
                                  {dashboardData.unique_mrf_replacement_for_candidate_offer_accepted
                                    ? dashboardData.unique_mrf_replacement_for_candidate_offer_accepted
                                    : '0'}
                                  /
                                  {dashboardData.total_offer_letter_accepted_candidate_for_replacement
                                    ? dashboardData.total_offer_letter_accepted_candidate_for_replacement
                                    : '0'}
                                </h5>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div
                  className="card"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px 0px grey',
                    minHeight: "160px"
                  }}
                >
                  <div className="col mrf-card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          onClick={() =>
                            getDataForHrDashBoard(
                              'joinedMrf',
                              'Total Closed MRF',
                              'close',
                              'all',
                            )
                          }
                          className="float-left  left-section"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <div className="justify-content-center">
                            <h6>CLOSED MRF</h6>
                            <h6 style={{ fontWeight: 700, fontSize: 22 }}>
                              {(dashboardData.total_candidate_joined_for_mrf_vacancy
                                ? dashboardData.total_candidate_joined_for_mrf_vacancy
                                : 0) +
                                (dashboardData.total_candidate_joined_for_mrf_replacement
                                  ? dashboardData.total_candidate_joined_for_mrf_replacement
                                  : 0)}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="float-right right-section"
                          style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'joinedVacancy',
                                  'Closed Vacancy',
                                  'close',
                                  'vacancy',
                                )
                              }
                              className="vacancy-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Vacancy</span>
                              <br />

                              <span>
                                <h6>
                                  {dashboardData.total_candidate_joined_for_mrf_vacancy
                                    ? dashboardData.total_candidate_joined_for_mrf_vacancy
                                    : '0'}
                                </h6>
                              </span>
                            </div>
                            <br />
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'joinedReplacement',
                                  'Closed Replacement',
                                  'close',
                                  'replacement',
                                )
                              }
                              className="replacement-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Replacement</span>
                              <br />
                              <span>
                                <h5>
                                  {dashboardData.total_candidate_joined_for_mrf_replacement
                                    ? dashboardData.total_candidate_joined_for_mrf_replacement
                                    : '0'}
                                </h5>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div
                  className="card"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 0px 10px 0px grey',
                    minHeight: "160px"
                  }}
                >
                  <div className="col mrf-card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          onClick={() =>
                            getDataForHrDashBoard(
                              'opsSelect',
                              'Total Candidate Joined',
                              'joined',
                              'all',
                              'joined',
                            )
                          }
                          className="float-left  left-section"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <div className="justify-content-center">
                            <h6>CANDIDATE JOINED</h6>
                            <h6 style={{ fontWeight: 700, fontSize: 22 }}>
                              {dashboardData.joined_candidate
                                ? dashboardData.joined_candidate
                                : '0'}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div
                          className="float-right right-section"
                          style={{
                            width: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'opsSelectVacancy',
                                  'Candidate Joined on Vacancy',
                                  'joined',
                                  'vacancy',
                                  'joined',
                                )
                              }
                              className="vacancy-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Vacancy</span>
                              <br />

                              <span>
                                <h6>
                                  {dashboardData.joined_vacancy == null
                                    ? 0
                                    : dashboardData.joined_vacancy}
                                </h6>
                              </span>
                            </div>
                            <br />
                            <div
                              onClick={() =>
                                getDataForHrDashBoard(
                                  'opsSelectReplacement',
                                  'Candidate Joined on Replacement',
                                  'joined',
                                  'replacement',
                                  'joined',
                                )
                              }
                              className="replacement-btn vacancyBtn"
                              style= {{cursor: 'pointer'}}
                            >
                              <span>Replacement</span>
                              <br />
                              <span>
                                <h5>
                                  {dashboardData.joined_replacement == null
                                    ? 0
                                    : dashboardData.joined_replacement}
                                </h5>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row middle-row ">
          <div className="col-md-8">
            <div className="card performance-card">
              <h5 className="card-header text-center">Performance(Days)</h5>
              <div className="card-body p-card">
                <div className="row">
                  <div className="col-md ">
                    <div className="card">
                      {/* <div className="col-md">
                                        <div className='float-left  performance-left-section'> */}
                      <div className="card-header performance-header">
                        <h5>MRF(Raised) to Join</h5>
                      </div>
                      <div className="card-body performance-card-body">
                        <h1 className="tat-no">
                          {avgTat && avgTat.raised_to_join}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md ">
                    <div className="card ">
                      {/* <div className="col-md">
                                        <div className='float-left  performance-left-section'> */}
                      <div className="card-header performance-header">
                        <h5>MRF(Manager) to Join</h5>
                      </div>
                      <div className="card-body performance-card-body">
                        <h1 className="tat-no">
                          {avgTat && avgTat.manager_to_join}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md ">
                    <div className="card ">
                      {/* <div className="col-md">
                                        <div className='float-left  performance-left-section'> */}
                      <div className="card-header performance-header">
                        <h5>MRF(Manager) to Offer</h5>
                      </div>
                      <div className="card-body performance-card-body">
                        <h1 className="tat-no">
                          {avgTat && avgTat.manager_to_offer}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-md ">
                    <div className="card ">
                      <div className="card-header performance-header">
                        <h5>MRF(HR) to Offer</h5>
                      </div>

                      <div className="card-body performance-card-body">
                        <h1 className="tat-no">
                          {avgTat && avgTat.hr_to_offer}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card recruiters-card">
              <h5 className="card-header text-center">Top recruiters</h5>
              <div className="card-body p-card">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S.N.</th>
                      <th scope="col">Name</th>
                      {/* <th scope="col">MRF Closed</th>
                      <th scope="col">Avg. TAT</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {topRecruiters &&
                      topRecruiters.length > 0 &&
                      topRecruiters.map((recruiters, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{recruiters.recruiter_name}</td>
                          {/* <td>{recruiters.total_number_of_mrf_closed}</td>
                          <td>{recruiters.average_mrf_tat}</td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HrDashboardModal
        isOpen={isOpen}
        isViewModalOpen={isViewModalOpen}
        modalHeading={modalHeading}
        onclickMrfData={onclickMrfData}
      />

      <HrDashboardJoinedModal
        isOpenJoined={isOpenJoined}
        isViewModalOpen={isViewModalOpen}
        modalHeading={'Joined Candidate'}
        onclickMrfData={onclickMrfData}
      />
    </>
  );
};
export default MrfDashboard;
