import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import { getLocalDataAsObject } from '../../utils/CoustomStorage';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CSelect,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import swal from 'sweetalert';
import Alert from 'src/alert/alert';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import Moment from 'react-moment';
import ModalProfileBank from './modal';
import { Link } from 'react-router-dom';
import ViewCandidate from '../viewCandidate/ViewCandidate';
import ModalCandidate from '../viewCandidate/modal';
import { isPermission } from 'src/utils/common';

const ProfileBank = props => {
  const {} = props;
  let api = new API();
  const [largeR, setLargeR] = useState(false);
  const [CandidateRecord, setCandidateRecord] = useState();
  const [setCandList, setCandidate] = useState();
  const [candidateWithoutMRF, setCandidateWithoutMRF] = useState();
  const [active, setActive] = useState(0);
  const [mrfRecord, setMrfRecord] = useState();
  const [createdCandidate, setCreatedCandidate] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingRequisition, setPendingRequisition] = useState();
  const [isCreateCondidateModalOpen, setIsCreateCondidateModalOpen] = useState(
    false,
  );
  const [applyJobdisable, setApplyJobdisable] = useState(false);
  const [DocDataR, setDocDataR] = useState('');
  const [DocData, setDocData] = useState('');
  const [isAlert, setIsAlert] = useState(false);
  const [isSelectedView, setIsSelectedView] = useState(false);
  const [MRFData, setMRFData] = useState();
  const [details, setDetails] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [email, setemail] = useState('');
  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };
  const [ReasonRef, setReasonRef] = useState({});
  useEffect(() => {
    getMrfRecord();
  }, []);

  const getMrfRecord = async () => {
    let result11 = await api.get(config.profile);
    if (result11) {
      setemail(result11.data[0].email);
    }
    let result = await api.get(config.profileBank);
    if (result && result.code == 200) {
      setCandidateRecord(result.data);
    } else {
    }
    let getrefandijp = await api.get(config.getallMRF_IJP_REF);
    if (getrefandijp && getrefandijp.code == 200) {
      setCandidate(getrefandijp.data);
    } else {
    }
    let getwithoutMrf = await api.get(config.getAllApplyJobDetails);
    if (getwithoutMrf && getwithoutMrf.code == 200) {
      setCandidateWithoutMRF(getwithoutMrf.data);
    } else {
    }
  };
  const onChangeInputRejectReason = e => {
    const { name, value } = e.target;
    setReasonRef({
      ...ReasonRef,
      [name]: value,
    });
  };

  const fields = [
    { label: 'MRF ID', key: 'mrf_id', _style: { width: '5%' } },
    { label: 'MRF Applying For', key: 'applying_for', _style: { width: '5%' } },
    { label: 'Department', key: 'department', _style: { width: '5%' } },
    { label: 'Designation', key: 'designation', _style: { width: '10%' } },
    { label: 'Job Type', key: 'job_type', _style: { width: '5%' } },
    { label: 'Last Date', key: 'lastdate', _style: { width: '5%' } },
    { label: 'MRF Status', key: 'status', _style: { width: '5%' } },
    {
      key: 'show_details',
      label: 'Action',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
  ];

  const fields1 = [
    { label: 'Candidate ID', key: 'id', _style: { width: '5%' } },
    { label: 'Candidate Name', key: 'name', _style: { width: '5%' } },
    { label: 'Candidate Email', key: 'email', _style: { width: '5%' } },
    { label: 'Mobile No.', key: 'mobile', _style: { width: '5%' } },
    { label: 'Designation', key: 'designation', _style: { width: '10%' } },
    { label: 'Created By', key: 'emp_name', _style: { width: '10%' } },
    {
      key: 'show_details',
      label: 'Action',
      _style: { width: '10%' },
      sorter: false,
      filter: false,
    },
  ];

  const toggleDetails = index => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const getBadge = status => {
    switch (status) {
      case 'Close':
        return 'danger';
      case 'Hold':
        return 'warning';
      default:
        return 'info';
    }
  };

  const applyJob = dataReview => {
    setApplyJobdisable(true);
    setDocData(dataReview);
    setLargeR(!largeR);
  };

  const isViewModalOpen = candidate => {
    setIsModalOpen(!isModalOpen);
    setCandidateDetails(candidate);
  };

  const view_selected = mrf => {
    setMRFData(mrf);
    setIsSelectedView(!isSelectedView);
  };

  const updateReason = async mrf_id => {
    const formData = {
      mrf_id: mrf_id,
      rejectReason: ReasonRef.rejectReason,
    };
    swal({
      content: true,
      title: 'Are you sure?',
      text: `Are you sure to reject`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updateRejectMrf, formData, true);
        if (result && result.code == 200) {
          isAlertToggle(result && result.message);
          setLargeR(!largeR);
        } else {
          isAlertToggle(result && result.message);
          setLargeR(!largeR);
        }
      }
    });
  };

  const updateShortList = async (id, name) => {
    const formData = {
      id: id,
      candidate_created_by: email,
      created_by: getLocalDataAsObject('user')[0].id,
    };
    swal({
      content: true,
      title: 'Are you sure?',
      text: `Are you sure to HR Shortlist ${name}`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updateHrShortList, formData, true);
        if (result && result.code == 200) {
          isAlertToggle(result && result.message);
        } else {
          isAlertToggle(result && result.message);
        }
      }
    });
  };
  return (
    <div className="">
      {isSelectedView == false && (
        <div className="container">
          <h3 className="text-uppercase text-center mt-3 mb-4">
            {' '}
            <u>Profile Bank Panel</u>
          </h3>
          <CRow>
            <CCol xs="12">
              <CCard>
                <CCardBody>
                  <CTabs
                    activeTab={active}
                    onActiveTabChange={idx => setActive(idx)}
                  >
                    <CNav variant="tabs">
                      <CNavItem>
                        <CNavLink>Referral Candidate</CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink>IJP Candidate</CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink>Candidate Without MRF</CNavLink>
                      </CNavItem>
                    </CNav>
                    <CTabContent>
                      <CTabPane>
                        <CDataTable
                                items={setCandList && setCandList.filter((candidate) => {
                                    if(candidate.job_type == "REF"){
                                    return candidate
                                    }
                                })}  
                                fields={fields && fields.filter((fd) => {
                                    if(fd?.key !== "show_details"){
                                        return fd;
                                    }
                                    else if(isPermission('view_referral_ijp_candidates') && fd?.key === "show_details"){
                                        return fd;
                                    }
                                    })}
                                    tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                                itemsPerPageSelect
                                itemsPerPage={10}
                                hover
                                pagination
                                scopedSlots={{
                                    status: (item) => (
                                        <td>
                                            <CBadge color={getBadge(item.status)}>
                                                {item.status}
                                            </CBadge>
                                        </td>
                                    ),
                                    show_details: (item, index) => {
                                        return (<>
                                            {isPermission('view_referral_ijp_candidates') &&
                                            <td className="py-2">
                                                <CButton onClick={() => view_selected(item)}
                                                        size="sm"
                                                        color="primary"
                                                        className="ml-1"
                                                    >
                                                        View Candidate
                                                    </CButton>
                                            </td>
                                          }
                                        </>);
                                    },
                                }}
                            />
                        </CTabPane>

                        <CTabPane>
                        <CDataTable
                                items={setCandList && setCandList.filter((candidate) => {
                                    if(candidate.job_type == "IJP"){
                                    return candidate
                                    }
                                })}  
                                fields={fields && fields.filter((fd) => {
                                    if(fd?.key !== "show_details"){
                                        return fd;
                                    }
                                    else if(isPermission('view_referral_ijp_candidates') && fd?.key === "show_details"){
                                        return fd;
                                    }
                                    })}
                                    tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                                itemsPerPageSelect
                                itemsPerPage={10}
                                hover
                                pagination
                                scopedSlots={{
                                    status: (item) => (
                                        <td>
                                            <CBadge color={getBadge(item.status)}>
                                                {item.status}
                                            </CBadge>
                                        </td>
                                    ),
                                    show_details: (item, index) => {
                                        return (
                                            <>
                                            {isPermission('view_referral_ijp_candidates') &&
                                            <td className="py-2">
                                                <CButton onClick={() => view_selected(item,index)}
                                                        size="sm"
                                                        color="primary"
                                                        className="ml-1"
                                                    >
                                                        View Candidate
                                                    </CButton>
                                            </td>
                                    }
                                        </>);
                                    },
                                }}
                            />
                </CTabPane>
                <CTabPane>
                        <CDataTable
                                items={candidateWithoutMRF && candidateWithoutMRF.filter((candidate) => {
                                    if(candidate.job_type == "without_mrf"){
                                    return candidate
                                    }
                                })}  
                                fields={fields1 && fields1.filter((fd) => {
                                    if(fd?.key !== "show_details"){
                                        return fd;
                                    }
                                    else if((isPermission('hr_shortlist') || isPermission('reject_candidate') || isPermission('download_resume_profile_bank')) && fd?.key === "show_details"){
                                        return fd;
                                    }
                                    })}
                                    tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                                itemsPerPageSelect
                                itemsPerPage={10}
                                hover
                                pagination
                                scopedSlots={{
                                show_details: (item, index) => {
                                        return (
                                <td className="py-2">
                                <CRow>
                                <CCol xs="12" md="3" style={{ marginRight: "7px" }}>
                                <>
                                {(isPermission('hr_shortlist') || isPermission('reject_candidate')) && 
                                <div className="btn-group">
                                    <button type="button" title="action" className="btn btn-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-list" aria-hidden="true"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                    {isPermission('hr_shortlist') &&
                                    <a className="dropdown-item" onClick={() => updateShortList(item.id,item.name)}>HR Shortlist</a>
                                    }
                                    <div className="dropdown-divider"></div>
                                    {isPermission('reject_candidate') &&
                                    <CButton color="secondary" className="dropdown-item" onClick={() => setLargeR(!largeR)}>Reject</CButton>
                                    }
                                    </div>
                                </div>
                                }
                                </>
                            </CCol>
                            {isPermission('download_resume_profile_bank') &&
                        <CCol xs="12" md="2">
                            <a href={"https://cbt.glueple.com:3021/candidate_resume/" + item.candidate_resume_filename} target="_blank"> <CButton data-toggle="tooltip" data-placement="Download" title="Download Resume" className="btn btn-success " ><i className="fa fa-download" aria-hidden="true"></i></CButton></a>
                        </CCol>
                                }
                        <CModal show={largeR} onClose={() => setLargeR(!largeR)} size="lg" color="success" >
                        <CModalHeader style={{ marginTop: "28px" }} closeButton>
                            <CModalTitle>Please Write Reason<br /></CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <CForm encType="multipart/form-data" className="form-horizontal" >
                            <CFormGroup row>
                        <CCol md="12">
                            <CLabel >Reason<span className="text-danger"> *</span></CLabel>
                            <CInput id="rejectReason" name="rejectReason" placeholder="Enter your reject reason" onChange={onChangeInputRejectReason} required />
                        </CCol>
                            </CFormGroup>
                            </CForm>
                        </CModalBody>
                        <CModalFooter>
                            <CButton color="success" onClick={() => updateReason(item.id)} >Submit</CButton>{' '}
                            <CButton color="secondary" onClick={() => setLargeR(!largeR)}>Back</CButton>
                        </CModalFooter>
                    </CModal>
                            <Alert
                                isAlert={isAlert}
                                isAlertToggle={isAlertToggle}
                                alertMessage={alertMessage}
                                isAlertToggleClose={isAlertToggleClose}
                            />
                        </CRow>
                                            </td>
                                        );
                                    },
                                }}
                            />
                        </CTabPane>

                </CTabContent>
            </CTabs>
        </CCardBody>
    </CCard>
        </CCol>
            </CRow>
            
            </div>
)}
      {isSelectedView && (
        <div className="container">
          <ViewCandidate MRFData={MRFData} view_selected={view_selected} />
        </div>
      )}
    </div>
  );
};
export default ProfileBank;
